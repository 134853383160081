import React from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";

const RefundPage = () => (
  <Layout>
    <SEO title="Refund Policy" />
    <section className="section">
      <Row>
        <Col>
          <h1>Refund Policy</h1>
          <p>
            <em>Last Updated: September 4, 2020</em>
          </p>
          <p>
            If you'd like a refund, please{" "}
            <a href="https://app.taplist.io/support">contact us</a>. We're happy
            to help out. This page describes our refund policy.
          </p>
          <h2>Our Satisfaction Guarantee</h2>
          <p>
           All Taplist.io plans are fully refundable, "no questions asked", in the
           first 30 days.
          </p>
          <h4>When refunds are granted</h4>
          <ol>
            <li>
              If you were just billed for your next period of service, but you
              mean to cancel, we're happy to refund that charge.
            </li>
            <li>
              If you've paid for a monthly or annual plan within the past 30
              days, we'll offer you a refund if you request it within 30 days of
              the charge.
            </li>
          </ol>
          <h4>When refunds are not granted</h4>
          <p>
            If you’ve purchased an annual plan over 30 days ago, we’ll issue
            credit to your account for the remaining balance on the annual plan.
            Unfortunately we cannot offer refunds past the 30 day point.
          </p>
        </Col>
      </Row>
    </section>
  </Layout>
);

export default RefundPage;
